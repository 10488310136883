<template>
<div>
        <v-card flat style="background-color: var(--v-background-base) !important">
            <v-card class="mx-auto" tile :max-width="!$vuetify.breakpoint.mobile ? '98vw' : '100%'" :style="{
                'margin-top': !$vuetify.breakpoint.mobile ? '0px' : '0px',
                'border-radius': !$vuetify.breakpoint.mobile ? '25px 25px 0 0' : '0px',}" 
                style="overflow-y: hidden;">
                <v-card-text :style="{
                    height: !$vuetify.breakpoint.mobile ? '100dvh' : '100dvh', 'overflow-y': 'hidden',}" 
                    class="pt-0 px-0 pb-0">
                    <v-toolbar class="tooly" dark flat tile style="height: 50px">
                        <v-btn icon @click="$router.go(-1)">
                            <v-icon>arrow_back</v-icon>
                        </v-btn>
                        <v-toolbar-title style="width: 20%;">
                            <v-text-field class="searchOrg" dense outlined hide-details placeholder="Search" prepend-inner-icon="search">
                            </v-text-field>
                        </v-toolbar-title>
                    </v-toolbar>
                    <div class="banner">
                    </div>
                    <div class="profileRow">
                        <div style="height: 40%; width: 100%; background-color: transparent !important;"></div>
                        <div class="orgInfo">
                            <h1 class="pb-1">{{organisation?.friendlyName ?? organisation?.name ?? ''}}</h1>
                            <h4>Fruit Forwarding Agents</h4>
                            <div class="d-flex align-end" style="height:2rem;" v-if="organisation">
                                <v-chip class="mr-2 mb-1" color="primary" x-small outlined v-for="(role, i) in orgRoles.filter(x => organisation[x.role])" :key="i"><v-icon x-small left>{{role.icon}}</v-icon>{{role.name}}</v-chip>
                            </div>
                        </div>
                        <v-card class="profilePhoto"></v-card>
                    </div>
                    <div class="bodySection">
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="3" class="pt-10 pl-8">
                                <h3 style="display: block; color: transparent !important;">Contact</h3>
                                <v-list v-if="organisation" class="mb-5">
                                    <v-list-item class="pr-0" dense v-for="address in organisation.organisationAddresses" :key="address.id">
                                        <v-list-item-avatar>
                                            <v-icon small>business</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title><h5 class="mr-2"><u>{{address.alias ?? ''}}</u></h5></v-list-item-title>
                                            <v-list-item-subtitle><h5 class="mr-2">{{address.addressLine1 ?? ''}}</h5></v-list-item-subtitle>
                                            <v-list-item-subtitle><h5 class="mr-2">{{address.addressLine2 ?? ''}}</h5></v-list-item-subtitle>
                                            <v-list-item-subtitle><h5 class="mr-2">{{address.addressLine3 ?? ''}}</h5></v-list-item-subtitle>
                                            <v-list-item-subtitle><h5 class="mr-2">{{address.addressLine4 ?? ''}}</h5></v-list-item-subtitle>
                                            <!-- <v-list-item-subtitle><h5 class="mr-2">{{address.subdivision}}</h5></v-list-item-subtitle> -->
                                            <!-- <v-list-item-subtitle><h5 class="mr-2">{{address.country}}</h5></v-list-item-subtitle> -->
                                        </v-list-item-content>
                                        <v-list-item-action class="pr-10" v-if="editing">
                                            <v-menu right offset-x :close-on-content-click="false">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn plain icon v-on="on" v-bind="attrs"><v-icon>edit</v-icon></v-btn>
                                                </template>
                                                <v-card style="min-width: 20rem;">
                                                    <v-card-text class="d-flex align-center" style="flex-direction: column;">
                                                        <v-text-field style="width: 100%" v-model="address.alias" counter maxLength="25" dense outlined label="Alias"></v-text-field>
                                                        <v-text-field style="width: 100%" v-model="address.addressLine1"  counter maxLength="30" dense outlined label="Address Line 1"></v-text-field>
                                                        <v-text-field style="width: 100%" v-model="address.addressLine2"  counter maxLength="30" dense outlined label="Address Line 2"></v-text-field>
                                                        <v-text-field style="width: 100%" v-model="address.addressLine3"  counter maxLength="30" dense outlined label="Address Line 3"></v-text-field>
                                                        <v-text-field style="width: 100%" v-model="address.addressLine4" class="mb-2" counter maxLength="30" dense outlined label="Address Line 4"></v-text-field>
                                                        <v-btn :loading="saveAddressLoading" outlined plain color="primary" small @click="saveAddress(address)"><v-icon small left>save</v-icon>Save</v-btn>
                                                    </v-card-text>
                                                </v-card>
                                            </v-menu>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                                <v-list-item class="pr-0" dense v-if="organisation?.telephoneNumber || organisation?.telephoneNumberAlt">
                                    <v-list-item-avatar>
                                        <v-icon small>phone</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title v-if="organisation?.telephoneNumber"><div class="d-flex"><h5 class="mr-2">{{organisation?.telephoneNumber ?? ''}}</h5><span> (Primary)</span></div></v-list-item-title>
                                        <v-list-item-title v-if="organisation?.telephoneNumberAlt"><div class="d-flex"><h5 class="mr-2">{{organisation?.telephoneNumberAlt ?? ''}}</h5><span> (Alt)</span></div></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="pr-0" dense v-if="organisation?.emailAddress || organisation?.emailAddressAlt">
                                    <v-list-item-avatar>
                                        <v-icon small>email</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title v-if="organisation?.emailAddress"><div class="d-flex"><h5 class="mr-2">{{organisation?.emailAddress ?? ''}}</h5><span>(Primary)</span></div></v-list-item-title>
                                        <v-list-item-title v-if="organisation?.emailAddressAlt"><div class="d-flex"><h5 class="mr-2">{{organisation?.emailAddressAlt ?? ''}}</h5><span>(Alt)</span></div></v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action class="pr-10" v-if="editing">
                                        <v-menu right offset-x :close-on-content-click="false">
                                            <template v-slot:activator="{on, attrs}">
                                                <v-btn plain icon v-on="on" v-bind="attrs"><v-icon>edit</v-icon></v-btn>
                                            </template>
                                            <v-card style="min-width: 20rem">
                                                <v-card-text  class="d-flex align-center" style="flex-direction: column;">
                                                    <v-text-field style="width: 100%;" v-model="organisation.telephoneNumber" label="Primary Telephone Number" dense outlined></v-text-field>
                                                    <v-text-field style="width: 100%;" v-model="organisation.telephoneNumberAlt" label="Alt Telephone Number" dense outlined></v-text-field>
                                                    <v-text-field style="width: 100%;" v-model="organisation.emailAddress" label="Primary Email Address" dense outlined></v-text-field>
                                                    <v-text-field style="width: 100%;" v-model="organisation.emailAddressAlt" label="Alt Email Address" dense outlined></v-text-field>
                                                    <v-text-field style="width: 100%;"  label="Website Url" dense outlined></v-text-field>
                                                    <v-btn :loading="saveContactLoading" outlined plain color="primary" small @click="saveOrgContact()"><v-icon small left>save</v-icon>Save</v-btn>
                                                </v-card-text>
                                            </v-card>
                                        </v-menu>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-list-item class="pr-0" dense>
                                    <v-list-item-avatar>
                                        <v-icon small>language</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title><a href="https://lclog.com"><u>https://lclog.com</u></a></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <!-- <v-col cols="12" sm="1" md="1"></v-col> -->
                            <v-col cols="12" sm="12" md="6" class="pt-10 px-8">
                                <h3>About <v-btn plain icon class="mb-2" small @click="editAbout = !editAbout"><v-icon small>edit</v-icon></v-btn></h3>
                                <v-textarea counter maxLength="1000" v-if="editAbout" outlined  v-model="about"></v-textarea>
                                <div v-if="!editAbout">
                                    <span style="white-space: pre-line;">{{ about }}</span>
                                </div>
                                <h3 class="mt-5 mb-2">Products</h3>
                                <v-chip class="mr-2 mb-1" color="primary" small outlined>Grapes</v-chip>
                                <v-chip class="mr-2 mb-1" color="primary" small outlined>Citrus</v-chip>
                                <v-chip class="mr-2 mb-1" color="primary" small outlined>Stonefruit</v-chip>
                                <v-chip class="mr-2 mb-1" color="primary" small outlined>Avocados</v-chip>
                                <h3 class="mt-5 mb-2">Industries</h3>
                                <v-chip class="mr-2 mb-1" color="primary" small outlined v-for="(item, i) in organisation?.industries" :key="i">{{item}}</v-chip>

                            </v-col>
                            <v-col cols="12" sm="1" md="1"></v-col>
                            <v-col cols="12" sm="12" md="2" class="pt-10 px-4">
                                <h3>Shared Contacts</h3>
                                <div v-for="contact in sharedContacts" :key="contact.id">
                                    <v-card style="width: 100%; height: 12vh; display: flex; border-radius: 20px !important;" class="mb-2">
                                        <v-card style="border-radius: 20px 0 0 20px !important; height: 100%; width: 40%; display: flex; flex-direction: column; background-color: var(--v-background-darken1) !important; display: flex; align-items: center; justify-content: center" flat tile>
                                            <v-avatar size="75">
                                                <v-icon v-if="!contact.user.avatar">person</v-icon>
                                                <v-img v-if="contact.user.avatar" :src="contact.user.avatar"></v-img>
                                            </v-avatar>
                                        </v-card>
                                        <v-card class="pt-2 pl-2" style="border-radius: 0 20px 20px 0 !important; line-height: 1.5; height: 100%; width: 60%; background-color: var(--v-background-base) !important;" flat tile>
                                            <v-list-item-subtitle style="opacity: 0.7"> <h5>{{ contact.user.firstname }} {{ contact.user.surname }} </h5></v-list-item-subtitle>
                                            <v-list-item-subtitle style="opacity: 0.7"> <h6>{{ contact.position }} </h6></v-list-item-subtitle>
                                            <div style="height: 70%; display: flex; flex-direction: column; justify-content: flex-end;">
                                                <h6 style="opacity: 0.7">{{ contact.organisationAddress.alias ?? '' }}</h6>
                                                <h6 style="opacity: 0.7">{{ contact.user.emailAddress }} </h6>
                                                <h6 style="opacity: 0.7">{{ contact.user.mobileNumber }} </h6>
                                            </div>
                                        </v-card>
                                    </v-card>
                                </div>
                                <!-- <v-list>
                                    <v-list-item class="px-0" dense two-line v-for="contact in sharedContacts" :key="contact.id">
                                        <v-list-item-avatar>
                                            <v-icon>person</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{contact.user.firstname}} {{ contact.user.surname }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ contact.position }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle>
                                                {{ contact.user.emailAddress }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-btn plain icon>
                                                <v-icon>email</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list> -->
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
            </v-card>
        </v-card>
</div>
</template>

    
<script>
export default {
    components: {},
    data: () => ({
        selectedOrg: {
            id: 2507
        },
        about: `Proudly committed to providing our clients with the best, most comprehensive logistics and transport services, we offer local know-how, global coverage and a complete focus on safeguarding your products. This guarantees that we deliver the freshest goods with the smoothest service and best price – every time. \n\nEstablished in 2001, LCL Logistics key focus remains centred on delivering the most advanced, efficient and consistently outstanding service in Global Logistics and the Supply Chain Management of Perishable goods.\n\nBy investing in the success of our people, while working within our company cornerstones of Integrity, Innovation and Intensity, we continually define an environment that drives solution-driven outcomes and an unbeatable standard of service excellence. Our understanding of modern techniques and our experience, as well as the drive and passion of our team is what enables us to provide the highest quality solutions.`,
        editing: true,
        editAbout: false,
        loadingContacts: false,
        saveContactLoading: false,
        sharedContacts: [],
        saveAddressLoading: false,
        organisation: null,
        params: {
            limit: 12,
            offset: 0,
            search: null,
            orgId: 2507
        },
        orgRoles: [
            {name: 'Consignee', role: 'consignee', icon: 'call_received'},
            {name: 'Buyer', role: 'buyer', icon: 'shopping_cart'},
            {name: 'Shipper', role: 'shipper', icon: 'north_east'},
            {name: 'Producer', role: 'producer', icon: 'factory'},
            {name: 'Notify Party', role: 'notifyParty', icon: 'notifications_active'},
            {name: 'Coldstore', role: 'coldstore', icon: 'store'},
            {name: 'Forwarder', role: 'forwarder', icon: 'fast_forward'},
            {name: 'Bank', role: 'bank', icon: 'account_balance'},
            {name: 'Shipping Line', role: 'shippingLine', icon: 'directions_boat'},
            {name: 'Governing Body', role: 'governingBody', icon: 'gavel'},
            {name: 'Plugin Provider', role: 'pluginProvider', icon: 'extension'},
            {name: 'Transporter', role: 'transporter', icon: 'local_shipping'},
            {name: 'Vas Provider', role: 'vasProvider', icon: 'settings_suggest'}
        ],
        updateKey: 0,
    }),
    watch: {},
    async created() {
        this.loadingContacts = true;
        this.getSharedContacts()
        this.getOrgInfo()
    },
    methods: {
        async getSharedContacts() {
            try {
                this.params.orgId = this.selectedOrg.id
                const response = await this.$API.getSharedContacts({params: this.params})
                this.sharedContacts = response
                console.log(this.sharedContacts)
                this.loadingContacts = false
            } catch (error) {
                this.loadingContacts = false
                this.$message({
                    message: 'Failed to load shared contacts',
                    type: 'error'
                })
            }
        },
        async saveOrgContact() {
            try {
                let obj = {
                    id: this.organisation.id,
                    name: this.organisation.name,
                    alias: this.organisation.alias,
                    telephoneNumber: this.organisation.telephoneNumber,
                    telephoneNumberAlt: this.organisation.telephoneNumberAlt,
                    emailAddress: this.organisation.emailAddress,
                    emailAddressAlt: this.organisation.emailAddressAlt
                }
                this.saveContactLoading = true
                let response = await this.$API.updateOrganisationAdmin(obj)
                this.saveContactLoading = false
                if(response) {
                    this.$message({
                        message: 'Contact updated',
                        type: 'success'
                    })
                }
            } catch (error) {
                this.saveContactLoading = false
                this.$message({
                    message: 'Failed to save contact',
                    type: 'error'
                })
            }
        },

        async saveAddress(address) {
            try {
                if(address.id) {
                    this.saveAddressLoading = true 
                    let response = await this.$API.updateOrganisationAddress(address)
                    this.saveAddressLoading = false 
                    if(response) {
                        this.$message({
                            message: 'Address updated',
                            type: 'success'
                        })
                    }
                }
            } catch (error) {
                this.$message ({
                    message: 'Failed to save address',
                    type: 'error'
                })
            }
        },

        async getOrgInfo() {
            try {
                const response = await this.$API.getOrganisationDetails({params: this.params})
                this.organisation = response
                if(this.organisation.industries != null) {
                    this.organisation.industries = JSON.parse(this.organisation.industries)
                }
                
            } catch (error) {
                this.$message({
                    message: 'Failed to load organisation info',
                    type: 'error'
                })
            }
        }
    }
};
</script>
    
    
<style scoped>
.dense-list-item {
    height: 60px;
}

.bodySection {
    display: flex;
    justify-content: center;
    height: 100%;
}

.banner {
    width: 100%; 
    height: 25%; 
    background: url(../../public/img/lclBanner.png) center center no-repeat  !important; 
    background-color:rgb(241, 236, 228) !important; 
    background-size: 20%;
}

.profileRow {
    overflow: visible;
    height: 12rem;
    contain: content;
    margin-top: -5rem;
}
.profilePhoto {
    bottom: 1rem;
    top: auto;
    left: 6%;
    position: absolute;
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
    background-color: cyan !important;
    background: url(https://d3fwccq2bzlel7.cloudfront.net/Pictures/280xAny/5/5/7/1557_2_24794.jpg) center center no-repeat !important;
    background-size: cover !important;
    border: 5px solid var(--v-greyRaised-base);
    z-index: 1;
}

.contentSection {
    background-color: var(--v-greyRaised-lighten1) !important; 
    height: 100%; 
    width: 70%;
    padding: 5rem 0 0 0;
    
}

.orgName {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
    justify-content: center !important;
}

.orgInfo {
    height: 60%; 
    padding: 1.4rem 0 0 19rem;
    line-height: 1.2;
    width: 100%; 
    background-color: var(--v-background-darken1) !important;
}
.searchOrg {
    border-radius: 20px;
}

.tooly ::v-deep .v-toolbar__content {
    height: 50px !important;
}
</style>
